import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "antd/dist/antd.css";
import "styles/index.scss";

import reportWebVitals from "reportWebVitals";
import App from "./App";

/* ---- i18n setup ---- */
import { I18nextProvider } from "react-i18next";
import i18nConfig from "./i18n";

/* ---- Apollo Client Setup ---- */
import ApolloProvider from "./apollo-setup";

ReactDOM.render(
  <ApolloProvider>
    <I18nextProvider i18n={i18nConfig}>
      <App />
    </I18nextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
