import React from "react";
import { message } from "antd";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import {
  getMainDefinition,
  offsetLimitPagination,
} from "@apollo/client/utilities";

import { useToastDispatch } from "contexts/toastContext";
import { API_ENDPOINT, API_WS_ENDPOINT } from "config";
import { getLocalToken } from "utils/util-funcs";

/* ---- Handle gql and network errors, business logic errors are handled in component ---- */
//  graphQLErrors, networkError;
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    message.error(`Something went wrong. Please refresh your page.`);
  } else if (networkError) {
    message.error(`Network error. Please refresh your page.`);
  }
});

/* ---- Apollo Client Setup ---- */
const wsLink = new WebSocketLink({
  uri: `wss://${API_WS_ENDPOINT}`,
  options: {
    reconnect: true,
  },
});

const httpLink = createHttpLink({
  uri: API_ENDPOINT,
});

/* ---- Handle authentication ---- */
const authLink = setContext((_, { headers }) => {
  // get the authentication, lang token from local storage if it exists
  const token = getLocalToken("auth");
  let lang = getLocalToken("locale");

  // On local developement, english is default
  if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
    lang = "jp";
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "Accept-Language": lang ? lang : "jp",
    },
  };
});

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   authLink.concat(httpLink)
// );

const links = from([
  // errorLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink)
  ),
]);

const client = new ApolloClient({
  link: links,
  cache: new InMemoryCache(),
});

export default function ApolloProviderSetup({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
