import React from "react";
import clsx from "clsx";

import { LoadingSpinner } from "components";
import styles from "./button.module.scss";

export default function Button({
  type = "button",
  classes = [],
  tabIndex,
  onClick = () => {},
  disabled = false,
  isProcessing = false,
  children,
  inlineStyles,
}) {
  return (
    <button
      tabIndex={tabIndex}
      type={type}
      disabled={disabled}
      className={clsx(styles.button, [...classes])}
      onClick={onClick}
      style={{ ...inlineStyles }} // To hanle wider signup form
    >
      {isProcessing ? (
        <LoadingSpinner size={16} color="secondary-white" />
      ) : (
        children
      )}
    </button>
  );
}
