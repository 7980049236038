import { useState, useEffect, createContext } from "react";
import { Layout } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { Sidebar, TopHeader, MainContent, LoadingSpinner } from "components";
import { useUserContext } from "contexts/userContext";
import { GET_ADVERTISER } from "services/queries";
import { REQUEST_EMAIL_CODE } from "services/mutations";

export default function BaseLayout({ userId }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const onCollapse = (collapsed) => {
    setIsCollapsed(collapsed);
  };

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // User data
  const { loading, data } = useQuery(GET_ADVERTISER, {
    variables: {
      userId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [requestCode] = useMutation(REQUEST_EMAIL_CODE);

  const [state, dispatch] = useUserContext();

  useEffect(() => {
    if (!loading && data?.advertiser?.advertiser) {
      dispatch({
        type: "STORE_USER",
        user: data?.advertiser?.advertiser,
      });
    }
  }, [loading]);
  useEffect(() => {
    console.log(data);
  }, [data]);

  // if user email is not confirmed, sent email confirmation code
  useEffect(() => {
    const sendEmailConfirmCode = async () => {
      if (!loading && data?.advertiser?.advertiser) {
        if (!data?.advertiser?.advertiser.email_confirmed) {
          await requestCode({
            variables: { userId, intent: "email_verification" },
          });
        }
      }
    };

    sendEmailConfirmCode();
  }, [loading]);

  // If user hasn't confirmed email, show verify email view
  // After user details is lodeded, then load main content area
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar
        isCollapsed={isCollapsed}
        handleCollapse={handleCollapse}
        onCollapse={onCollapse}
      />
      {loading && !state.username ? (
        <div className="d-f f-jc f-aic wd-100">
          <LoadingSpinner />
        </div>
      ) : (
        <Layout style={{ position: "stickey" }}>
          <TopHeader
            isCollapsed={isCollapsed}
            handleCollapse={handleCollapse}
          />
          <MainContent userId={userId} />
        </Layout>
      )}
    </Layout>
  );
}
