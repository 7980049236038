export const getLocalToken = (tokenType) => {
  // if is auth return default auth token;
  if (tokenType === "auth") {
    return localStorage.getItem("meem-adv-auth");
  } else if (tokenType === "locale") {
    return localStorage.getItem("meem-adv-locale");
  }
};

export const setLocalToken = (tokenType, token) => {
  if (tokenType === "auth") {
    // remove exisitng token,
    localStorage.removeItem("meem-adv-auth");
    // set new token
    localStorage.setItem("meem-adv-auth", token);
  } else if (tokenType === "locale") {
    localStorage.setItem("meem-adv-locale", token);
  }
};

export const getItemFromObjArr = (objArr, keyToMatch, valueToMatch) => {
  const item = objArr.filter((i) => i[keyToMatch] === valueToMatch)[0];

  return item;
};

// Pass the value, creates cart item
export const createCartItem = ({
  id,
  bannerId,
  slotDate,
  bannerPositionId,
  banner,
  bannerPosition,
  price,
}) => {
  return {
    id,
    bannerId,
    banner,
    slotDate,
    bannerPositionId,
    bannerPosition,
    price,
  };
};

// awaiting_payment -> Awaiting Payment
// https://www.digitalocean.com/community/tutorials/js-capitalizing-strings
export const formatStatusStr = (str) => {
  return str.split("_").join(" ");

  //     .map((i) =>
  //   i.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
  // )
};
