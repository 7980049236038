import { Suspense, lazy } from "react";
import { Route, Redirect, Switch, useParams } from "react-router-dom";
import { Layout } from "antd";
import { GET_ADVERTISER } from "services/queries";
import { LazyTopLoader } from "components";
import { useUserState } from "contexts/userContext";
import SkeletonLoader from "components/Skeleton/SkeletonLoader";
import { useQuery } from "@apollo/client";
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const Profile = lazy(() => import("pages/Profile/Profile"));
const Campaigns = lazy(() => import("pages/Campaigns/Campaigns"));
const SlotContainer = lazy(() => import("pages/Slots"));
const Banners = lazy(() => import("pages/Banners/Banners"));
const FAQ = lazy(() => import("pages/FAQ/FAQ"));
const NotFound = lazy(() => import("pages/NotFound/NotFound"));
const Onboarding = lazy(() => import("pages/Onboarding/Onboarding"));
const Chat = lazy(() => import("pages/Chat/ChatContainer"));
const CreateCampaign = lazy(() =>
  import("pages/CreateCampaign/CreateCampaignContainer")
);

const ChangePassword = lazy(() =>
  import("pages/ChangePassword/ChangePassword")
);
const SavedCards = lazy(() => import("pages/SavedCards/SavedCards"));

const ConfirmEmailLink = lazy(() =>
  import("pages/ConfirmEmailLink/ConfirmEmailLink")
);

const { Content } = Layout;

export default function MainContent({ userId }) {
  const { loading, data, refetch } = useQuery(GET_ADVERTISER, {
    variables: {
      userId,
    },
  });

  return (
    <Content
      style={{
        margin: "1.5rem 1rem",
        padding: 24,
        paddingBottom: 0,
        paddingRight: 0,
        background: "#fff",
        minHeight: "280px",
        borderRadius: "4px",
      }}
    >
      <Suspense fallback={<SkeletonLoader />}>
        <Switch>
          <Route path="/chat" component={Chat} />
          <Route
            exact
            path="/confirm-email"
            render={(props) => (
              <ConfirmEmailLink {...props} id={userId} refetchUser={refetch} />
            )}
          />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/" component={ApprovedRoutes} />
        </Switch>
      </Suspense>
    </Content>
  );
}

function ApprovedRoutes() {
  const { status } = useUserState();

  // if (!status) {
  //   return <LazyTopLoader />;
  // }

  if (!status) {
    return null;
  }
  // Only allow approved routes, if user is apporved
  return (
    <>
      {status === "approved" ? (
        <>
          <Suspense fallback={<SkeletonLoader />}>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/:id/profile" component={Profile} />
              <Route path="/campaigns" component={Campaigns} />
              <Route path="/create-campaign" component={CreateCampaign} />
              <Route path="/password-change" component={ChangePassword} />
              <Route path="/saved-cards" component={SavedCards} />
              <Route path="/FAQ" component={FAQ} />
              <Route
                path="/campaign/:campaignId/slots"
                component={SlotContainer}
              />
              <Route path="/banners" component={Banners} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </>
      ) : (
        <Redirect to="/onboarding" />
      )}
    </>
  );
}
