import { createContext, useReducer, useContext } from "react";

const UserStateContext = createContext();
const UserDispatchContext = createContext();

/* ---- Initial State Object ---- */
const initalState = {
  id: null,
  username: null,
  credit: null,
  address: "",
  companyName: "",
  email: "",
  phoneCC: "",
  phoneNO: "",
  companyState: "",
  companyWebsite: "",
  country: "",
  postalCode: "",
  fax: "",
  representativeName: "",
  representativeEmail: "",
  reprresentativeDepartment: "",
  active: "",
  logo: "",
  logoUploaded: "",
  emailConfirmed: "placeholder", // workaround for null === false on initial empty check for email confirmed
  phoneConfirmed: "",
  createdAt: "",
  updatedAt: "",
  status: "",
  statusDescription: "",
  skipCreditSubscription: false,
  personInchargePhoneCC: "",
  personInchargePhoneNO: "",
  personInchargeDesignation: "",
  personInchargeName: "",
  companyIndustryArea: "",
};

/* ---- Transforms state based on action ---- */
const userReducer = (state, action) => {
  switch (action.type) {
    case "STORE_USER": {
      const {
        id,
        user_name,
        email,
        company_website_url,
        company_industry_area,
        city,
        country,
        phone_cc,
        phone_no,
        postal_code,
        address_1,
        address_2,
        active,
        email_confirmed,
        credit,
        status,
        logo,
        logo_uploaded,
        fax_number,
        representative_name,
        representative_dept,
        representative_email,
        created_at,
        updated_at,
        status_description,
        company_name,
        stripe_customer_id,
        person_incharge_name,
        person_incharge_designation,
        person_incharge_phone_cc,
        person_incharge_phone_no,
        payment_method_exists,
      } = action.user;

      // To handle name conflict with state variable object
      const userState = action.user.state;
      return {
        ...state,
        userId: id,
        username: user_name,
        companyName: company_name,
        companyWebsite: company_website_url,
        companyIndustryArea: company_industry_area,
        city: city,
        companyState: userState,
        country: country,
        phoneCC: phone_cc,
        phoneNO: phone_no,
        email: email,
        active: active,
        status: status,
        logo: logo,
        logoUploaded: logo_uploaded,
        fax: fax_number,
        representativeName: representative_name,
        representativeEmail: representative_email,
        reprresentativeDepartment: representative_dept,
        createdAt: created_at,
        updatedAt: updated_at,
        address_1: address_1,
        address_2: address_2,
        emailConfirmed: email_confirmed,
        postalCode: postal_code,
        // credit: credit,
        credit: state.skipCreditSubscription ? state.credit : credit, // When skipCreditSubscription, store the existing credit
        statusDescription: status_description,
        stripeCustomerId: stripe_customer_id,
        personInchargePhoneCC: person_incharge_phone_cc,
        personInchargePhoneNO: person_incharge_phone_no,
        personInchargeName: person_incharge_name,
        personInchargeDesignation: person_incharge_designation,
        paymentMethodExists: payment_method_exists,
        ...action.user,
      };
    }
    case "STORE_UPDATED_CREDIT": {
      return {
        ...state,
        credit: action.updatedCredit,
      };
    }

    case "TOGGLE_SKIP_CREDIT_SUBSCRIPTION": {
      return {
        ...state,
        skipCreditSubscription: !state.skipCreditSubscription,
      };
    }

    case "TOGGLE_PAYMENT_METHOD_EXISTS": {
      return {
        ...state,
        paymentMethodExists: true,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initalState);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
};

const useUserContext = () => {
  return [useUserState(), useUserDispatch()];
};
export { UserProvider, useUserDispatch, useUserState, useUserContext };
