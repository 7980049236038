import { Popover, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import JapanFlag from "assets/images/jp-locale.svg";
import UsaFlag from "assets/images/us-locale.svg";
import { getLocalToken, setLocalToken } from "utils/util-funcs";

import "./locale-selector.scss";

export default function LocaleSelector() {
  const { t, i18n } = useTranslation();

  const localLang = getLocalToken("locale");
  // Current selected lang by user or User stored one or eng
  const currentLang = i18n.languages[0] || localLang || "en";

  return (
    <div className="locale-container">
      <Tooltip placement="left" title={t("select language")}>
        <Popover placement="bottomRight" content={<Locales />} trigger="click">
          {currentLang === "en" ? (
            <img src={UsaFlag} className="locale-current" />
          ) : (
            <img src={JapanFlag} className="locale-current" />
          )}
        </Popover>
      </Tooltip>
    </div>
  );
}

function Locales() {
  const { i18n } = useTranslation();

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    // Update localestorage locale, to be sent with backend requests
    setLocalToken("locale", code);
  };

  return (
    <>
      <div
        onClick={() => changeLanguage("en")}
        className="locale-flag-container"
      >
        <img src={UsaFlag} className="locale-flag" />
        <p>English</p>
      </div>
      <div
        onClick={() => changeLanguage("jp")}
        style={{ marginTop: "1rem" }}
        className="locale-flag-container"
      >
        <img src={JapanFlag} className="locale-flag" />
        <p>日本語</p>
      </div>
    </>
  );
}
