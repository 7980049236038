import React, { createContext, useReducer, useContext } from "react";

const AppStateContext = createContext();
const AppDispatchContext = createContext();

/* ---- Initial State Object ---- */
const initalState = {
  a: 0,
};

/* ---- Transforms state based on action ---- */
const appReducer = (state, action) => {
  switch (action.type) {
    case "INCREMENT": {
      return { a: state.a + 1 };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initalState);
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

const useAppState = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
};

const useAppDispatch = () => {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a AppProvider");
  }
  return context;
};

const useAppContext = () => {
  return [useAppState(), useAppDispatch()];
};
export { AppProvider, useAppDispatch, useAppContext };
