import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles, TextField, InputAdornment } from "@material-ui/core";
import { MdLock } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { MuiButton, FormErrorMsg } from "components";
import { useToastDispatch } from "contexts/toastContext";
import { useUserState } from "contexts/userContext";
import { REQUEST_EMAIL_CODE, VERIFY_EMAIL } from "services/mutations";
import { GET_ADVERTISER } from "services/queries";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    maxWidth: "100vw",
    paddingBottom: "2rem",
  },
});

/* ---- 1. REQUEST EMAIL CONFIRMATION CODE ---- */
function RequestCode({ userId, setCodeSent }) {
  const [requestCode, { loading, data }] = useMutation(REQUEST_EMAIL_CODE, {
    onCompleted(data) {
      if (data.create_verification_code.error) {
      } else {
        setCodeSent(true);
      }
    },
  });

  return (
    <>
      <h1 style={{ marginBottom: "1rem", textAlign: "center" }}>
        Email not confirmed
      </h1>
      <p
        className="form-subtitle"
        style={{ marginBottom: "2rem", textAlign: "center" }}
      >
        Confirm your email to continue
      </p>
      <MuiButton
        customClasses="margin-r-2"
        onClick={() =>
          requestCode({ variables: { userId, intent: "email_verification" } })
        }
        // onClick={() => setCodeSent(true)}
        disabled={loading || data}
      >
        Request Email Confirmation Code
      </MuiButton>
    </>
  );
}

/* ---- 2. ENTER EMAIL CONFIRMATION CODE ---- */
function EnterCode({ userId }) {
  const { t } = useTranslation();

  const [code, setCode] = useState("");
  const [status, setStatus] = useState({
    type: "",
    msg: "",
  });

  const handleChange = (event) => {
    setCode(event.target.value);
    setStatus({ ...status, type: "" });
  };

  const dispatch = useToastDispatch();
  const history = useHistory();
  const [verifyCode, { loading, data }] = useMutation(VERIFY_EMAIL, {
    refetchQueries: [
      {
        query: GET_ADVERTISER,
        variables: {
          userId,
        },
      },
    ],
    onCompleted(data) {
      if (data.verify_email.error) {
        setStatus({
          ...status,
          type: "ERROR",
          msg: data.verify_email.error,
        });
      } else {
        dispatch({
          type: "SHOW_TOAST",
          message: `${t("your email has been successfully verified")}`,
          mode: "success",
        });

        history.replace("/");
        // // Redirect to dashboard
        // setTimeout(() => {
        // }, 2000);
      }
    },
  });

  return (
    <>
      <h1 style={{ marginBottom: "1rem", textAlign: "center" }}>
        {t("enter confirmation code")}
      </h1>
      <p
        className="form-subtitle"
        style={{ marginBottom: "2rem", textAlign: "center" }}
      >
        {t("confirmation code has been delivered to your email")}
      </p>
      {status.type === "ERROR" && <FormErrorMsg message={status.msg} />}

      <TextField
        variant="outlined"
        margin="normal"
        label={`${t("enter code")}`}
        name="code"
        helperText={status.type === "required" && status.msg}
        error={status.type === "required" ? true : false}
        id="outlined-code"
        value={code}
        onChange={handleChange}
        type="text"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdLock style={{ opacity: 0.7 }} />
            </InputAdornment>
          ),
        }}
      />
      <MuiButton
        customClasses="margin-r-2"
        disabled={loading || data}
        onClick={() => {
          if (!code) {
            setStatus({ type: "required", msg: "Code Required" });
            return false;
          }
          verifyCode({ variables: { userId, code: code.trim() } });
        }}
      >
        {t("confirm email")}
      </MuiButton>
    </>
  );
}

export default function ConfirmEmail() {
  const classes = useStyles();
  const { userId } = useUserState();

  const [codeSent, setCodeSent] = useState(false);

  return (
    <Grid
      container
      classes={{
        root: classes.root,
      }}
      justify="center"
    >
      <Grid item>
        <div className="form" style={{ marginTop: "10vh" }}>
          <EnterCode userId={userId} />
        </div>
      </Grid>
    </Grid>
  );
}
