import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { AppProvider } from "contexts/appContext";
import { UserProvider } from "contexts/userContext";
import { ToastProvider } from "contexts/toastContext";
import { LazyTopLoader, ErrorFallback, NotifcationToast } from "components";

import { themeObj } from "styles/muiTheme";

/* ---- Lazy load base app routes ---- */
const Login = lazy(() => import("pages/Login/Login"));
const PrivateRoute = lazy(() => import("pages/PrivateRoute/PrivateRoute"));
const Signup = lazy(() => import("pages/Signup/Signup"));
const ForgotPassword = lazy(() =>
  import("pages/ForgotPassword/ForgotPassword")
);
const ResetPasswordLink = lazy(() =>
  import("pages/ResetPasswordLink/ResetPasswordLink")
);
const BaseLayout = lazy(() => import("components/BaseLayout/BaseLayout"));

/* ---- Material UI theme setup ---- */
const theme = createMuiTheme({
  ...themeObj,
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <NotifcationToast />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LazyTopLoader />}>
            <AppProvider>
              <Router>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/signup" component={Signup} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/password-reset"
                    component={ResetPasswordLink}
                  />

                  <UserProvider>
                    <PrivateRoute path="/" component={BaseLayout} />
                  </UserProvider>
                </Switch>
              </Router>
            </AppProvider>
          </Suspense>
        </ErrorBoundary>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
