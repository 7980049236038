import { useContext, useState, useEffect } from "react";
import { Layout, Button, Popover, List, Typography, Divider } from "antd";
import { IconButton, Badge, Grid } from "@material-ui/core";
import { AiFillStepBackward, AiFillStepForward } from "react-icons/ai";
import { MdNotificationsNone } from "react-icons/md";
import { useTranslation } from "react-i18next";

import {
  UserProfileMenu,
  WalletCredit,
  LocaleSelector,
  StripeAlert,
  StripeDetailsModal,
} from "components";
import { useUserContext } from "contexts/userContext";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import AppNotification from "components/AppNotification";
import CardHeader from "components/CardHeader/index";
import LanguageIcon from "@material-ui/icons/Language";
import clsx from "clsx";
import styles from "./topHeader.module.scss";
//import "styles/jumboStyle/app.scss";

const { Header } = Layout;

const data = [
  "Racing car sprays burning fuel into crowd.",
  "Japanese princess to wed commoner.",
  "Australian walks 100km after outback crash.",
  "Man charged over missing wedding girl.",
  "Los Angeles battles huge wildfires.",
];

export default function TopHeader({ isCollapsed, handleCollapse }) {
  /* ---- Notification Menu ---- */
  function NotificationMenuItem({ username, actionStr, timeStr }) {
    return (
      <div className={styles.notificationItem}>
        <div>
          <p>
            <strong>{username}</strong> {actionStr}
          </p>
          <p style={{ opacity: ".5" }}>{timeStr}</p>
        </div>
      </div>
    );
  }
  const { t } = useTranslation();

  function NotificationMenu() {
    return (
      <div className={clsx(styles.accountMenu, styles.notificationMenu)}>
        <h2>{t("notifications")}</h2>
        {/* <Divider /> */}
        <NotificationMenuItem
          username="New Year"
          actionStr={t("... has been approved")}
          timeStr={t("... minutes ago")}
        />
        <NotificationMenuItem
          username="Vision"
          actionStr={t("... clicked on your advertisment")}
          timeStr={t("... hours ago")}
        />
        <NotificationMenuItem
          username="Rohan"
          actionStr={t("... has viewed")}
          timeStr={t("... hours ago")}
        />
        <NotificationMenuItem
          username="Level Up"
          actionStr={t("has been posted")}
          timeStr={t("... weeks ago")}
        />
      </div>
    );
  }

  const [isStripeDetailsOpen, setIsStripeDetailsOpen] = useState(false);

  const [state, dispatch] = useUserContext();

  const {
    userId,
    companyName,
    stripeCustomerId,
    paymentMethodExists,
    status,
  } = state;

  return (
    <>
      {isStripeDetailsOpen && (
        <StripeDetailsModal
          className="addCardDetails"
          isOpen={isStripeDetailsOpen}
          onCancel={() => setIsStripeDetailsOpen(false)}
        />
      )}
      <Header style={{ background: "#fff", padding: 0, paddingLeft: 16 }}>
        <Button
          className="trigger"
          type="secondary"
          icon={isCollapsed ? <AiFillStepForward /> : <AiFillStepBackward />}
          style={{ cursor: "pointer" }}
          onClick={handleCollapse}
        />
        <Grid container justify="flex-end" spacing={2} style={{ margin: 0 }}>
          {/* Visible if no stipe billing added or no payment method*/}
          {/* {status === "approved" &&
            userId &&
            stripeCustomerId &&
            !paymentMethodExists && (
              <StripeAlert onClick={() => setIsStripeDetailsOpen(true)} />
            )} */}
          {status === "approved" && (
            <StripeAlert onClick={() => setIsStripeDetailsOpen(true)} />
          )}
          {/* Only fetch wallet comp when userId exists in state, to deal with async state update of react */}
          {userId && <WalletCredit />}
          <p
            style={{
              // fontWeight: "bold",
              fontSize: "1rem",
              paddingRight: ".75rem",
            }}
          >
            {companyName}
          </p>
          <div
            style={{
              //marginLeft: buttonWidth,
              clear: "both",
              whiteSpace: "nowrap",
            }}
          >
            <Popover
              placement="bottomRight"
              //title={text}
              content={NotificationMenu} //{content}
              trigger="click"
              style={{
                //marginLeft: buttonWidth,
                height: "30vh",
              }}
            >
              <IconButton aria-label="show 7 new notifications" color="inherit">
                <Badge badgeContent={4} color="primary">
                  <MdNotificationsNone />
                </Badge>
              </IconButton>
            </Popover>
          </div>
          <UserProfileMenu />
          <LocaleSelector />
        </Grid>
      </Header>
    </>
  );
}
