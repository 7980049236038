import { gql } from "@apollo/client";

/* ---- AUTHENTICATION ---- */
export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(user_name: $username, password: $password) {
      error
      token {
        access_token
        token_type
      }
    }
  }
`;
export const REFRESH_TOKEN = gql`
  mutation loginRefresh {
    login_refresh {
      error
      token {
        access_token
        token_type
      }
    }
  }
`;
export const SIGNUP = gql`
  mutation Signup($username: String!, $password: String!, $email: String!) {
    create_advertiser(
      details: { user_name: $username, password: $password, email: $email }
    ) {
      error
      advertiser {
        id
        user_name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: UUID!
    $username: String
    $password: String
    $email: String
    $phoneCC: String
    $phoneNO: String
    $address1: String
    $address2: String
    $city: String
    $companyState: String
    $postalCode: String
    $personInchargePhoneCC: String
    $personInchargePhoneNO: String
    $personInchargeDesignation: String
    $personInchargeName: String
    $companyName: String
    $companyWebsite: String
    $companyIndustryArea: String
    $country: String
    $representativeName: String
    $representativeEmail: String
    $reprresentativeDepartment: String
  ) {
    update_advertiser(
      id: $id
      details: {
        user_name: $username
        password: $password
        email: $email
        phone_cc: $phoneCC
        phone_no: $phoneNO
        address_1: $address1
        address_2: $address2
        city: $city
        state: $companyState
        postal_code: $postalCode
        person_incharge_phone_cc: $personInchargePhoneCC
        person_incharge_phone_no: $personInchargePhoneNO
        person_incharge_designation: $personInchargeDesignation
        person_incharge_name: $personInchargeName
        company_name: $companyName
        company_website_url: $companyWebsite
        company_industry_area: $companyIndustryArea
        country: $country
        representative_name: $representativeName
        representative_email: $representativeEmail
        representative_dept: $reprresentativeDepartment
      }
    ) {
      error
      advertiser {
        id
        user_name
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout {
      error
      logged_out
    }
  }
`;

/* ---- JOYRIDE ---- */
export const UNSET_JOYRIDE = gql`
  mutation UnsetJoyride($id: UUID!) {
    unset_advertiser_joyride_flag(id: $id) {
      error
      unset
    }
  }
`;

export const DELETE_SAVED_CARD = gql`
  mutation DeleteSavedCard($pmId: String!) {
    remove_payment_method(pm_id: $pmId) {
      error
      deleted
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $username: String!
    $code: String!
    $newPassword: String!
  ) {
    reset_password(
      user_name: $username
      code: $code
      new_password: $newPassword
    ) {
      error
      password_reset
    }
  }
`;

/* ---- USER VERIFICATION ---- */
export const REQUEST_EMAIL_CODE = gql`
  mutation RequestEmailVerifyCode(
    $userId: UUID
    $username: String
    $intent: VerificationCodeIntent!
  ) {
    create_verification_code(
      user_id: $userId
      user_name: $username
      intent: $intent
    ) {
      error
      verification_code_sent
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($userId: UUID!, $code: String!) {
    verify_email(user_id: $userId, code: $code) {
      error
      verification_code_matched
    }
  }
`;

/* ---- CREDIT-PAYMENT ---- */
export const CREATE_CREDIT = gql`
  mutation CreateCredit(
    $userId: UUID!
    $amount: Int!
    $bankTransactionId: String!
  ) {
    create_credit(
      details: {
        user_id: $userId
        amount: $amount
        bank_transaction_id: $bankTransactionId
      }
    ) {
      error
      credit {
        id
        amount
      }
    }
  }
`;

// Create payment intent for stripe
export const CREATE_CREDIT_TRANSACTION = gql`
  mutation CreateCreditTransaction(
    $userId: UUID!
    $amount: Int!
    $useSavedCard: Boolean!
  ) {
    create_credit_transaction(
      details: {
        user_id: $userId
        amount: $amount
        use_saved_card: $useSavedCard
      }
    ) {
      error
      stripe_client_secret
      transaction {
        id
        amount
        user_id
        ref_id
        status
        transaction_type
      }
    }
  }
`;

/* ---- CAMPAIGNS ---- */
export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign(
    $name: String!
    $advertiserId: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    create_campaign(
      details: {
        name: $name
        advertiser_id: $advertiserId
        start_date: $startDate
        end_date: $endDate
      }
    ) {
      error
      campaign {
        id
        name
        start_date
        end_date
        status
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $campaignId: UUID!
    $name: String
    $startDate: Date
    $endDate: Date
    $status: CampaignStatusUpdation
  ) {
    update_campaign(
      id: $campaignId
      details: {
        name: $name
        start_date: $startDate
        end_date: $endDate
        status: $status
      }
    ) {
      error
      campaign {
        id
        name
        start_date
        end_date
        status
      }
    }
  }
`;

export const CANCEL_CAMPAIGN = gql`
  mutation CancelCampaign($campaignId: UUID!) {
    cancel_campaign(id: $campaignId) {
      error
      campaign {
        id
        advertiser_id
        name
        status
        status_description
      }
    }
  }
`;

/* ------BANNERS ---- */
export const CREATE_BANNER = gql`
  mutation BannerCreation($advertiserId: UUID!, $link: String, $name: String!) {
    create_banner(
      details: { advertiser_id: $advertiserId, link: $link, name: $name }
    ) {
      error
      banner {
        id
        name
        link
      }
    }
  }
`;

export const UPDATE_BANNER = gql`
  mutation BannerUpdation(
    $bannerId: UUID!
    $link: String
    $name: String
    $status: BannerStatusUpdation
    $status_description: String
    $active: Boolean
  ) {
    update_banner(
      id: $bannerId
      details: {
        link: $link
        name: $name
        status: $status
        active: $active
        status_description: $status_description
      }
    ) {
      error
      banner {
        id
        name
        link
        status
        status_description
        active
      }
    }
  }
`;

/* ---- SLOTS ---- */
export const CREATE_SLOT = gql`
  mutation CreateSlot(
    $campaignId: UUID!
    $bannerId: UUID!
    $bannerPositionId: UUID!
    $date: Date!
  ) {
    create_slot(
      details: {
        campaign_id: $campaignId
        banner_id: $bannerId
        banner_position_id: $bannerPositionId
        date: $date
      }
    ) {
      error
      slot {
        id
        date
        price
      }
    }
  }
`;

export const UPDATE_SLOT = gql`
  mutation UpdateSlot($slotId: UUID!, $bannerId: UUID!) {
    update_slot(id: $slotId, details: { banner_id: $bannerId }) {
      error
      slot {
        id
      }
    }
  }
`;

export const DELETE_SLOT = gql`
  mutation DeleteSlot($slotId: UUID!) {
    delete_slot(id: $slotId) {
      error
      deleted
    }
  }
`;

export const ADD_STRIPE_PAYMENT_METHOD = gql`
  mutation AddStripePaymentMethod($id: UUID!) {
    add_payment_method(user_id: $id) {
      error
      setup_intent {
        id
        client_secret
        payment_method_types
      }
    }
  }
`;

export const CREATE_ADVERTISER_TICKET = gql`
  mutation AdvertiserTicketCreation(
    $advertiserId: UUID!
    $description: String!
  ) {
    create_advertiser_ticket(
      details: { advertiser_id: $advertiserId, description: $description }
    ) {
      error
      advertiser_ticket {
        id
        advertiser_id
        description
        status
        status_description
        created_at
      }
    }
  }
`;

export const CREATE_TICKET_CHAT_MESSAGE = gql`
  mutation createTicketChatMessage($details: TicketChatMessageCreation!) {
    create_ticket_chat_message(details: $details) {
      error
      ticket_chat_message {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
    }
  }
`;

export const UPDATE_TICKET_CHAT_MESSAGE = gql`
  mutation updateTicketChatMessage($ticket_id: UUID!, $id: String!) {
    update_ticket_chat_message(ticket_id: $ticket_id, id: $id) {
      error
      ticket_chat_message {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
    }
  }
`;
export const UPDATE_TICKET_CHAT_TYPING = gql`
  mutation updateTicketChatTyping($ticket_id: UUID!) {
    update_ticket_chat_typing(ticket_id: $ticket_id) {
      error
      updated
    }
  }
`;
