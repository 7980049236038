import React from "react";

import { SkeletonElement } from "./SkeletonElement";

const SkeletonTable = ({ hideHeader }) => {
  return (
    <div className={`skeleton-wraper light `}>
      <div className="skeleton-table">
        <table className="table">
          {!hideHeader && (
            <thead>
              <tr>
                <th scope="col">
                  <SkeletonElement type="header" />
                </th>
                <th scope="col">
                  <SkeletonElement type="header" />
                </th>
                <th scope="col">
                  <SkeletonElement type="header" />
                </th>
                <th scope="col">
                  <SkeletonElement type="header" />
                </th>
                <th scope="col">
                  <SkeletonElement type="header" />
                </th>
                <th scope="col">
                  <SkeletonElement type="header" />
                </th>
                <th scope="col">
                  <SkeletonElement type="header" />
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {new Array(10).fill("").map((item, i) => (
              <tr key={i}>
                <th scope="row">
                  <SkeletonElement type="title" />
                </th>
                <td>
                  <SkeletonElement type="title" />
                </td>
                <td>
                  <SkeletonElement type="title" />
                </td>
                <td>
                  <SkeletonElement type="title" />
                </td>
                <td>
                  <SkeletonElement type="title" />
                </td>
                <td>
                  <SkeletonElement type="title" />
                </td>
                <td>
                  <SkeletonElement type="title" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SkeletonTable;
