import ErrorImg from "assets/images/crash-error.svg";
import { MuiButton } from "components";

export default function ErrorFallback({ error }) {
  return (
    <div role="alert" style={{ maxWidth: "40rem", margin: "0 auto" }}>
      <img
        src={ErrorImg}
        alt="Error Illustration"
        className="ta-c margin-b-2"
      />
      <div className="m-0a ta-c" style={{ maxWidth: "50%" }}>
        <h1 className="ta-c">Something went wrong</h1>
        <MuiButton>
          <a href="/">Go back to home</a>
        </MuiButton>
      </div>
    </div>
  );
}
