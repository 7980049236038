export const themeObj = {
  typography: {
    fontFamily: `'Noto Sans JP', sans-serif`,
  },
  palette: {
    primary: { 500: "#180f77" },
  },
  // "primary-color-1": "#1924C0",
  // "primary-color-2": "#3385FF",
  "primary-color-1": "#180f77",
  "primary-color-2": "#180f77",
};
