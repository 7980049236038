import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function MuiFormTextField({
  name,
  errors,
  value,
  handleChange,
  startAdornment,
  register,
  customClasses = "",
  formConditions = false,
  strName = "",
  requiredStr = "",
}) {
  const { t } = useTranslation();

  const combineCustomClasses = (commaStrings) => {
    return commaStrings.split(",").join(" ");
  };

  return (
    <TextField
      className={`${combineCustomClasses(customClasses)}`}
      variant="outlined"
      margin="normal"
      label={t(`${strName}`)}
      name={name}
      helperText={errors?.[name]?.message}
      error={errors[name] ? true : false}
      id={`outlined-${name}`}
      defaultValue={value}
      onChange={handleChange(`${name}`)}
      // labelWidth={70}
      type="text"
      InputProps={{
        startAdornment,
      }}
      inputRef={register({
        ...(requiredStr && { required: `${t(requiredStr)}` }),
        ...(formConditions && { ...formConditions }), // Dynamically get form conditon
      })}
    />
  );
}
