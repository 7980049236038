import { gql } from "@apollo/client";

export const GET_UPDATED_CREDIT = gql`
  subscription Credit($userId: UUID!, $token: String!) {
    credit(user_id: $userId, token: $token) {
      error
      credit
    }
  }
`;

export const SUB_TICKET_CHAT_MESSAGE = gql`
  subscription ticketChatMessage($token: String!, $ticket_id: UUID!) {
    ticket_chat_message(token: $token, ticket_id: $ticket_id) {
      error
      event
      ticket_chat_message {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
      typing_user_id
    }
  }
`;
