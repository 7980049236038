import { Modal, Form, Input, DatePicker, Button } from "antd";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useToastDispatch } from "contexts/toastContext";
import { UPDATE_CAMPAIGN } from "services/mutations";
import { GET_CAMPAIGNS } from "services/queries";

const { RangePicker } = DatePicker;

export default function UpdateCampaign({
  userId,
  isOpen,
  handleCancel,
  updatingCampaign,
  refetchQueries = [],
}) {
  const { t } = useTranslation();

  const toastDispatch = useToastDispatch();

  const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN, {
    refetchQueries: refetchQueries,
  });

  const validateUpdateSlotRange = (slots = [], startDate, endDate) => {
    const existingSlotDatesArr = slots.map((s) => s?.date);

    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");

    const isBetween = existingSlotDatesArr.every((item, idx) => {
      const slotDate = moment(item, "YYYY-MM-DD");

      // Date must be in range: 2020-12-10 --- [2020-12-13, 2020-12-18] --- 2020-12-31
      if (!slotDate.isBetween(start, end, "days", "[]")) return false;

      return true;
    });

    return isBetween;
  };

  const onFinish = async (fieldsValue) => {
    const rangeValue = fieldsValue["campaignRange"];

    /* ---- Existing slot date cannot be out of range of new slot range ---- */
    // Check only when date ranges are being updated
    const isDateRangeValid = validateUpdateSlotRange(
      updatingCampaign.slots,
      rangeValue[0].format("YYYY-MM-DD"),
      rangeValue[1].format("YYYY-MM-DD")
    );

    if (!isDateRangeValid) {
      toastDispatch({
        type: "SHOW_TOAST",
        message: `${t(
          "selected date range cannot out of bound of existing slot"
        )}`,
        mode: "error",
      });
      return;
    }

    const values = {
      ...(fieldsValue["campaignName"] !== updatingCampaign.name && {
        name: fieldsValue["campaignName"],
      }), // Only update values if it's changed
      ...(rangeValue &&
        rangeValue[0].format("YYYY-MM-DD") !== updatingCampaign.start_date && {
          startDate: rangeValue[0].format("YYYY-MM-DD"),
        }),
      ...(rangeValue[1].format("YYYY-MM-DD") !== updatingCampaign.end_date && {
        endDate: rangeValue[1].format("YYYY-MM-DD"),
      }),
      campaignId: updatingCampaign.id,
    };

    const res = await updateCampaign({
      variables: { ...values },
    });

    if (res?.data?.update_campaign?.error) {
      toastDispatch({
        type: "SHOW_TOAST",
        message: res?.data?.update_campaign?.error,
        mode: "error",
      });
    } else if (res?.data?.update_campaign?.campaign.id) {
      toastDispatch({
        type: "SHOW_TOAST",
        message: `${res?.data?.update_campaign?.campaign.name} ${t(
          "campaign has been updated successfully"
        )} `,
        mode: "success",
      });
      handleCancel();
    }
  };

  return (
    <Modal
      title={t("update campaign")}
      visible={isOpen}
      onCancel={!loading && handleCancel}
      onOk={onFinish}
      footer={[
        <Button
          type="primary"
          form="updateCampaign"
          key="submit"
          htmlType="submit"
          disabled={loading}
        >
          {t("update campaign")}
        </Button>,
      ]}
    >
      <Form
        id="updateCampaign"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          campaignName: updatingCampaign.name,
          campaignRange: [
            moment(updatingCampaign.start_date),
            moment(updatingCampaign.end_date),
          ],
        }}
      >
        <Form.Item label={t("campaign name")} name="campaignName">
          <Input placeholder={t("enter campaign name")} />
        </Form.Item>
        <Form.Item label={t("campaign range")} name="campaignRange">
          <RangePicker
            disabledDate={(current) =>
              (current < moment().startOf("day") && current) ||
              moment().add(4, "months") < current
            }
          />
        </Form.Item>
      </Form>{" "}
    </Modal>
  );
}
