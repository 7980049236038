import { createContext, useReducer, useContext } from "react";

const ToastStateContext = createContext();
const ToastDispatchContext = createContext();

/* ---- Initial State Object ---- */
const initalState = {
  isVisible: false,
  message: "",
  mode: "",
};

/* ---- Transforms state based on action ---- */
const toastReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_TOAST": {
      const { message, mode } = action;
      return {
        isVisible: true,
        message: message,
        mode: mode || "info", // "info", "success", "error", "warning"
      };
    }
    case "HIDE_TOAST": {
      return {
        showToast: false,
        message: action.message,
        mode: action.mode,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, initalState);
  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        {children}
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

const useToastState = () => {
  const context = useContext(ToastStateContext);
  if (context === undefined) {
    throw new Error("useToastState must be used within a ToastProvider");
  }
  return context;
};

const useToastDispatch = () => {
  const context = useContext(ToastDispatchContext);
  if (context === undefined) {
    throw new Error("useToastDispatch must be used within a ToastProvider");
  }
  return context;
};

const useToastContext = () => {
  return [useToastState(), useToastDispatch()];
};
export { ToastProvider, useToastDispatch, useToastState, useToastContext };
