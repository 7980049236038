import React from "react";
import { SkeletonElement } from "./SkeletonElement";
import SkeletonTable from "./SkeletonTable";

export default function SkeletonLoader() {
  return (
    <div style={{ paddingRight: "1.5rem", paddingBottom: "1.5rem" }}>
      <div className="d-f f-jsb f-aibl margin-b-1">
        <div
          className="d-f jss f-aibl"
          style={{ width: "-webkit-fill-available" }}
        >
          <SkeletonElement type="button" />
          <SkeletonElement type="button" />
          <SkeletonElement type="button" />
        </div>
        <SkeletonElement type="button" />
      </div>
      <SkeletonTable />
      <div className="d-f f-jfe margin-b-2">
        {/* <SkeletonElement type="button" /> */}
      </div>
    </div>
  );
}
