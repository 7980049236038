import { Menu } from "@material-ui/core";

export default function MenuWrapper({ anchorEl, handleClose, menuListComp }) {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {menuListComp}
    </Menu>
  );
}
