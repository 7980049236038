import { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { TextField, Divider, CircularProgress } from "@material-ui/core";

import { ADD_STRIPE_PAYMENT_METHOD } from "services/mutations";

import { useUserState, useUserDispatch } from "contexts/userContext";
import { useToastDispatch } from "contexts/toastContext";
import {
  EnterPasswordModal,
  StripeCard,
  StripeElementWrapper,
} from "components";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function StripeDetailsModal({ isOpen, onCancel }) {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [addPaymentMethod] = useMutation(ADD_STRIPE_PAYMENT_METHOD);

  const { userId, username } = useUserState();
  const toastDispatch = useToastDispatch();
  const userDispatch = useUserDispatch();

  const [paymentStatus, setPaymentStatus] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });

  const handleCreateClientSecret = async () => {
    const paymentIntentData = await addPaymentMethod({
      variables: { id: userId },
    });

    // Handle error
    if (paymentIntentData.data.add_payment_method.error) {
      toastDispatch({
        type: "SHOW_TOAST",
        message: paymentIntentData.data.add_payment_method.error,
        mode: "error",
      });
      onCancel();
      return;
    }

    setStatus("PROCESSING");

    const res = await handleStripePaymentMethod(
      paymentIntentData.data.add_payment_method.setup_intent.client_secret
    );
  };

  const handleStripePaymentMethod = async (clientSecret) => {
    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    });

    // Handle stripe error
    if (error) {
      toastDispatch({
        type: "SHOW_TOAST",
        message: error.message,
        mode: "error",
      });
      onCancel();
    } else {
      if (setupIntent.status === "succeeded") {
        userDispatch({ type: "TOGGLE_PAYMENT_METHOD_EXISTS" });
        toastDispatch({
          type: "SHOW_TOAST",
          message: `${t("card has been saved successfully in your account.")}`,
          mode: "success",
        });

        onCancel();
      }
    }
  };

  async function handleAddCardDetails() {
    setStatus("AWAITING_LOGIN");
  }

  return (
    <>
      {status === "AWAITING_LOGIN" && (
        <EnterPasswordModal
          isOpen={status === "AWAITING_LOGIN"}
          onCancel={() => setStatus(null)}
          execFunc={handleCreateClientSecret}
        />
      )}
      <Modal
        title={t("add card to your account")}
        visible={isOpen}
        footer={null}
        closable={true}
        // width={700}
        onCancel={onCancel}
        destroyOnClose
      >
        <div style={{ paddingBottom: "1rem" }}>
          <StripeCard
            saveCard={true}
            enterBillingDetails={true}
            billingDetails={billingDetails}
            setBillingDetails={setBillingDetails}
            elements={elements}
            paymentStatus={paymentStatus}
            onExecFunc={handleAddCardDetails}
            error={error}
            setError={setError}
          />
        </div>
      </Modal>
    </>
  );
}

export default function WrappedContainer({
  isOpen,
  onCancel,
  enterBillingDetails,
}) {
  return (
    <StripeElementWrapper>
      <StripeDetailsModal isOpen={isOpen} onCancel={onCancel} />
    </StripeElementWrapper>
  );
}
