import clsx from "clsx";
import { useTranslation } from "react-i18next";

import styles from "./form-input.module.scss";

export default function FormInput({
  label,
  name,
  register,
  value,
  onChange = () => {},
  customClasses,
  errors,
  tabIndex,
  type = "text",
  width,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        styles.formField,
        width === "half" && styles.halfWidth,
        width === "third" && styles.thirdWidth
      )}
    >
      {label && (
        <label htmlFor={name} className={clsx(styles.formLabel)}>
          {t(label)}
          {errors?.[name] && (
            <span className={styles.formErrortext}>
              {t(errors[name]?.message)}
            </span>
          )}
        </label>
      )}

      <input
        tabIndex={tabIndex}
        id={name}
        name={name}
        type={type}
        ref={register}
        value={value}
        onChange={onChange(name)}
        className={clsx({
          [styles.formFieldError]: errors?.[name],
        })}
      />
    </div>
  );
}
