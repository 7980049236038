import { useState } from "react";
import { Modal } from "antd";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Grid,
  InputAdornment,
  IconButton,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import { MdVisibility, MdVisibilityOff, MdLock } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { useToastDispatch } from "contexts/toastContext";
import { useUserState } from "contexts/userContext";
import {
  AuthContainer,
  LoadingSpinner,
  FormErrorMsg,
  MuiButton,
  MuiFormTextField,
} from "components";
import { LOGIN } from "services/mutations";
import { setLocalToken } from "utils/util-funcs";

export default function EnterPasswordModal({ isOpen, onCancel, execFunc }) {
  const { t } = useTranslation();
  const toastDispatch = useToastDispatch();
  const { userId, username } = useUserState();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { register, handleSubmit, errors } = useForm();

  const [formValues, setFormValues] = useState({
    password: "",
  });
  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [login] = useMutation(LOGIN);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const res = await login({
      variables: { ...data, username },
    });

    if (res?.data?.login?.error) {
      toastDispatch({
        type: "SHOW_TOAST",
        message: res?.data?.login?.error,
        mode: "error",
      });
      setIsSubmitting(false);
    } else if (res?.data?.login?.token) {
      setLocalToken("auth", res.data.login.token.access_token);

      await execFunc();
      setIsSubmitting(false);
    }
  };

  console.log({ isSubmitting });
  return (
    <Modal
      title={null}
      visible={isOpen}
      onCancel={!isSubmitting && onCancel}
      footer={null}
    >
      <div className="form" style={{ boxShadow: "none", minWidth: "0px" }}>
        <h1 style={{ marginBottom: "1rem", textAlign: "center" }}>
          {t("please enter your password")}
        </h1>
        {/* <p
          className="form-subtitle"
          style={{ marginBottom: "2rem", textAlign: "center" }}
        >
          {t("login to your account")}
        </p> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <TextField
              variant="outlined"
              margin="normal"
              label={t("password")}
              name="password"
              helperText={errors?.password?.message}
              error={errors.password ? true : false}
              id="outlined-password"
              value={formValues.password}
              onChange={handleChange("password")}
              // labelWidth={70}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdLock style={{ opacity: 0.7 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              inputRef={register({
                required: `${t("password required")}`,
                // minLength: {
                //   value: 2,
                //   message: "Password must have at least 2 characters",
                // },
              })}
            />
            <div>
              <MuiButton
                type="submit"
                customClasses="margin-r-2"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <LoadingSpinner size={20} />
                ) : (
                  `${t("continue")}`
                )}
              </MuiButton>
            </div>
          </Grid>
        </form>
      </div>
    </Modal>
  );
}
