import { useState, useEffect } from "react";
import UseAnimations from "react-useanimations";
import alertCircle from "react-useanimations/lib/alertCircle";
import {
  MdAdd,
  MdAddCircle,
  MdAddBox,
  MdAddCircleOutline,
} from "react-icons/md";
import { useTranslation } from "react-i18next";

import { useUserState } from "contexts/userContext";

import { useQuery } from "@apollo/client";
import { GET_ADVERTISER } from "services/queries";

export default function StripeAlert({ onClick }) {
  const { t } = useTranslation();

  const { userId } = useUserState(); //useContext(UserContext);

  const [savedCard, setSavedCard] = useState([]);

  const { loading, data, fetchMore } = useQuery(GET_ADVERTISER, {
    variables: {
      userId: userId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  var cardsArr = [];

  useEffect(() => {
    const getCardDetail = async () => {
      if (!loading && data?.advertiser?.advertiser?.payment_methods) {
        cardsArr = await data?.advertiser?.advertiser?.payment_methods.map(
          (cpn) => {
            return {
              cpn,
            };
          }
        );
      }

      setSavedCard(cardsArr);
    };

    getCardDetail();
  }, [loading, data]);

  // console.log("No of Cards: ", savedCard.length);

  return (
    <div
      style={{ display: "flex", marginRight: "1rem", cursor: "pointer" }}
      onClick={onClick}
    >
      {savedCard.length >= 1 && (
        <>
          <MdAddCircleOutline
            color="#180f77"
            size={20}
            style={{
              alignSelf: "center",
              marginRight: ".25rem",
              cursor: "pointer",
            }}
          />
        </>
      )}
      {savedCard.length == 0 && (
        <>
          <UseAnimations
            animation={alertCircle}
            size={32}
            strokeColor="#f64e60"
            wrapperStyle={{
              alignSelf: "center",
              marginTop: "-.75rem",
              marginRight: ".25rem",
              cursor: "pointer",
            }}
          />
        </>
      )}

      <p>{t("add payment details")}</p>
    </div>
  );
}
