import { Select } from "antd";

const { Option } = Select;

export default function SelectDropdown({
  onChange,
  options,
  optKey,
  optValKey,
  placeholder,
}) {
  return (
    <>
      <Select
        style={{ width: 160 }}
        onChange={onChange}
        placeholder={placeholder}
      >
        {options.map((option) => (
          <Option value={option[optValKey]} key={optValKey}>
            {option[optKey]}
          </Option>
        ))}
      </Select>
    </>
  );
}
