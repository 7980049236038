import { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import { MdAccountBalanceWallet } from "react-icons/md";

import { GET_UPDATED_CREDIT } from "services/subscriptions";
import { useUserContext } from "contexts/userContext";

export default function WalletCredit() {
  const [state, dispatch] = useUserContext();
  const { userId, credit, skipCreditSubscription } = state;

  const authToken = localStorage.getItem("meem-adv-auth");
  const { data, loading } = useSubscription(GET_UPDATED_CREDIT, {
    variables: { userId, token: authToken },
    fetchPolicy: "network-only",
    skip: skipCreditSubscription, // Skip when doing credit payment and don't wanna show credit update to user
  });

  useEffect(() => {
    if (!loading && data?.credit?.credit) {
      dispatch({
        type: "STORE_UPDATED_CREDIT",
        updatedCredit: data?.credit.credit,
      });
    }
  }, [loading, data]);

  return (
    <div className="d-f f-aic" style={{ marginRight: "1.5rem" }}>
      <MdAccountBalanceWallet style={{ fontSize: "1.4rem", opacity: ".8" }} />{" "}
      <p
        style={{
          paddingLeft: ".4rem",
          fontWeight: "500",
          fontSize: "1.2rem",
          opacity: ".8",
        }}
      >
        ¥{data?.credit?.credit?.toLocaleString() || credit?.toLocaleString()}{" "}
      </p>
    </div>
  );
}
