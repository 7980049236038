import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme["primary-color-2"],
  },
}));

export default function LoadingSpinner({
  size = 40,
  thickness = 3.6,
  styles = "",
}) {
  const classes = useStyles();

  return (
    <CircularProgress
      className={styles}
      size={size}
      thickness={thickness}
      classes={{
        root: classes.root,
      }}
    />
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  styles: PropTypes.string,
};
