import { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
// ant-design/icons: Due to special use case with collapasible icon support
import {
  HomeOutlined,
  BarChartOutlined,
  CommentOutlined,
  FileImageOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import { MdAddCircle, MdLiveHelp } from "react-icons/md";
import { useTranslation } from "react-i18next";

import TextLogo from "assets/images/logo-text.svg";
import SquareLogo from "assets/images/logo-square.svg";
import Logo from "assets/images/logo.svg";

import Joyride from "react-joyride";

import { useQuery, useMutation } from "@apollo/client";
import { UNSET_JOYRIDE } from "services/mutations";
import { GET_ADVERTISER, GET_BANNERS } from "services/queries";

import { useUserState } from "contexts/userContext";

const { Sider } = Layout;

function TopLogo({ src, styles }) {
  return <img src={src} style={{ ...styles }} alt="Top Logo" />;
}

export default function Sidebar({ isCollapsed, onCollapse }) {
  const { t } = useTranslation();
  const { userId } = useUserState(); //useContext(UserContext);

  const [session, setSession] = useState([]);
  const [joyrideStatus, setJoyrideStatus] = useState(false);
  const [joyrideVisible, setJoyrideVisible] = useState(true);
  const { loading, data, fetchMore } = useQuery(GET_ADVERTISER, {
    variables: {
      userId: userId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });
  // eslint-disable-next-line no-console
  // console.log("Advertisers Data: ", data); //data fetched
  // console.log(data.);

  /*----------Sessions Data-----------*/
  var sessionArr = [];

  var joyriderStatus;

  const getJoyriderDetail = async () => {
    if (!loading && data?.advertiser?.advertiser?.show_joyride) {
      joyriderStatus = await data?.advertiser?.advertiser?.show_joyride;

      setJoyrideStatus(joyriderStatus);

      // console.log("rahul", joyrideStatus);
      return {
        joyrideStatus,
      };
    }
  };

  useEffect(() => {
    const getSessionsDetail = async () => {
      if (!loading && data?.advertiser?.advertiser?.sessions) {
        sessionArr = await data?.advertiser?.advertiser?.sessions.map((cpn) => {
          return {
            ...cpn,
          };
        });
      }

      setSession(sessionArr);
      // console.log("Sessions Data :", sessionArr);
    };

    getSessionsDetail();
    getJoyriderDetail();
  }, [loading, data]);

  // console.log("Sessions:", sessionArr);
  // console.log("Sessions Console:", { session });

  /*----------Joyride Data-----------*/
  // const [joyride, setJoyride] = useState("");

  // var joyrideStatus;

  // console.log("Joyrider:", joyrideStatus);

  const [UnsetJoyride] = useMutation(UNSET_JOYRIDE);

  // const handleJoyrideCallback = () => {
  //   const { status, type } = data;
  //   const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

  //   if (finishedStatuses.includes(status)) {
  //     this.setState({ run: false });
  //   }

  //   console.groupCollapsed(type);
  //   console.log(data);
  //   console.groupEnd();
  // };

  //   setJoyride(joyrideStatus);
  //   // console.log("Card Data :");
  // }

  //   getJoyriderDetail();

  // }, [loading, data]);

  let location = useLocation();

  // const [initialSteps, useSteps] = useState([
  const initialSteps = [
    {
      target: ".bannersCreation",
      content: `${t("joyride.banner")}`,
      disableBeacon: true,
    },
    {
      target: ".campaignsCreation",
      content: `${t("joyride.campaign")}`,
    },
    {
      target: ".supportDetails",
      content: `${t("joyride.queries")}`,
    },
    {
      target: ".faqPane",
      content: `${t("joyride.faq")}`,
    },
  ];

  /* ---- Getting Joyride only in the first(or second) login ---- */
  // console.log("Session Data:", { session });

  // const noOfSessions = Object.keys({ session }).length;
  const noOfSessions = session.length;
  // console.log("No. of sessions: ", noOfSessions);

  // if (noOfSessions > 2) {
  //   {initialSteps} === {initialSteps};
  // } else {
  //   {initialSteps} = null;
  // }

  /* ---- Handle matching sidebar highlighted tab on hard page refresh ---- */
  let key = 1;
  if (
    location.pathname === "/campaigns" ||
    location.pathname.includes("/campaign")
  ) {
    key = 3;
  } else if (location.pathname === "/create-campaign") {
    key = 2;
  } else if (location.pathname === "/banners") {
    key = 4;
  } else if (location.pathname === "/chat") {
    key = 5;
  } else if (location.pathname === "/faq") {
    key = 6;
  } else if (location.pathname === "/profile") {
    key = 7;
  } else if (location.pathname === "/password-change") {
    key = 8;
  } else if (location.pathname === "/saved-cards") {
    key = 9;
  }

  return (
    <>
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={onCollapse}
        trigger={null}
      >
        <div className="logo">
          {!isCollapsed ? (
            <TopLogo src={Logo} styles={{ fontSize: "1rem" }} />
          ) : (
            <TopLogo src={Logo} />
          )}
        </div>
        {/* {(!session && noOfSessions < 2) || */}
        {joyrideStatus && joyrideStatus === true && joyrideVisible && (
          <Joyride
            // joyrideService={joyrideService}
            steps={initialSteps}
            continuous={true}
            showSkipButton={true}
            // disableOverlay={true}
            disableOverlayClose={true}
            // disableCloseOnEsc={false}
            disableBeacon={true}
            callback={async (data) => {
              if (data.action == "reset") {
                const res = await UnsetJoyride({
                  variables: { id: userId },
                });
                console.log(res);
              } else if (data.action === "close") {
                // props.endTutorial();
                setJoyrideVisible(false);
                console.log("Clicked on Close");
              }
              // console.log(data);
              // console.log(userId);
            }}
            styles={{
              tooltipContainer: {
                textAlign: "left",
              },
              buttonNext: {
                backgroundColor: "#180F77",
              },
              buttonBack: {
                marginRight: 10,
              },
            }}
            locale={{
              end: "End Tour",
              close: "Close Tour",
              skip: "Close tour",
              last: "Close",
            }}
          />
        )}
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[`${key}`]}>
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <span>{t("dashboard")}</span>
            <Link to="/dashboard" />
          </Menu.Item>
          <Menu.Item
            className="campaignsCreation"
            key="2"
            icon={<MdAddCircle />}
          >
            <span>{t("create campaign")}</span>
            <Link to="/create-campaign" />
          </Menu.Item>
          <Menu.Item key="3" icon={<BarChartOutlined />}>
            <span>{t("campaigns")}</span>
            <Link to="/campaigns" />
          </Menu.Item>
          <Menu.Item
            key="4"
            className="bannersCreation"
            icon={<FileImageOutlined />}
          >
            <span>{t("banners")}</span>
            <Link to="/banners" />
          </Menu.Item>
          <Menu.Item
            key="5"
            className="supportDetails"
            icon={<CommentOutlined />}
          >
            <span>{t("support.support")}</span>
            <Link to="/chat" />
          </Menu.Item>
          <Menu.Item key="6" className="faqPane" icon={<MdLiveHelp />}>
            <span>{t("faq")}</span>
            <Link to="/faq" />
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
}
