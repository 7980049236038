import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme["primary-color-1"],
    textTransform: "initial",
    color: "#fff",
    height: "3rem",
    marginTop: "1rem",
    transition: "box-shadow 300ms",
    boxShadow: "none",
    opacity: ".9",

    "&:hover": {
      backgroundColor: theme["primary-color-1"],
      opacity: "1",
      boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
    },
  },
}));

export default function MuiButton({
  type = "button",
  customClasses = "",
  children,
  disabled = false,
  onClick = () => {},
}) {
  const classes = useStyles();

  // Combines comma seperated into a string
  const combineCustomClasses = (commaStrings) => {
    return commaStrings.split(",").join(" ");
  };

  return (
    <Button
      role="button"
      type={type}
      variant="contained"
      fullWidth
      disableElevation
      className={`${classes.button} ${combineCustomClasses(customClasses)}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
