import PropTypes from "prop-types";
import { Grid, makeStyles, Button } from "@material-ui/core";
import AuthPattern from "assets/images/auth-pattern.svg";
import TextLogo from "assets/images/logo-text.svg";
import Logo from "assets/images/logo.svg";

const useStyles = makeStyles({
  root: {
    // backgroundImage: (props) =>
    //   props.showBackground ? `url(${AuthPattern})` : "",
    minHeight: "100vh",
    maxWidth: "100vw",
    paddingBottom: "2rem",
  },
});

export default function AuthContainer({ children, showBackground = true }) {
  const classes = useStyles({ showBackground });

  return (
    <Grid
      container
      classes={{
        root: classes.root,
      }}
      justify="center"
      alignItems="center"
      style={{ backgroundColor: "#f7fafc" }}
    >
      <Grid item>
        {showBackground && (
          <Grid container justify="center">
            <img
              src={Logo}
              style={{
                width: "6rem",
                marginBottom: "5vh",
                marginTop: "10vh",
              }}
              alt="MeeM text logo"
            />
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
}

AuthContainer.propTypes = {
  children: PropTypes.element.isRequired,
  showBackground: PropTypes.bool,
};
