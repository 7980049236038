import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useToastContext } from "contexts/toastContext";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NotifcationToast() {
  const [state, dispatch] = useToastContext();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Pass the current message and toasttype to stop collapising blank step UI issue
    dispatch({
      type: "HIDE_TOAST",
      message: state.message,
      mode: state.mode,
    });
  };

  return (
    <Snackbar
      open={state.isVisible}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={state.mode}>
        {state.message}
      </Alert>
    </Snackbar>
  );
}
