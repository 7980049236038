/* ---- USED FOR LOGIN, SIGNUP, RESET PASSWORD base container ---- */
import { motion, AnimatePresence } from "framer-motion";
import { FiAlertTriangle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import { Button } from "components";

import TextLogo from "assets/images/logo-text.svg";
import Logo from "assets/images/logo.svg";
import styles from "./auth-form.module.scss";

export default function AuthForm({
  children,
  footer,
  handleSubmit,
  onSubmit,
  formStatus,
  buttonLabel = "submit",
  title,
  subTitle,
  inlineStyles,
  isDisabled,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className="flex flex-col">
        <form
          className={styles.form}
          style={{ ...inlineStyles }} // To hanle wider signup form
          onSubmit={onSubmit}
        >
          <div className={styles.logoContainer}>
            <img src={Logo} className={styles.logo} alt="MeeM text logo" />
          </div>
          {/* <h1 className={styles.title}>{t(`${title}`)}</h1> */}
          <h1 className={styles.title}>{t(title)}</h1>
          {subTitle && <p className={styles.subTitle}>{t(subTitle)}</p>}

          {children}
          <AnimatePresence>
            {formStatus.status === "ERROR" && (
              <motion.div
                className={styles.errorMsg}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <motion.p style={{ marginRight: "4px" }}>
                  <FiAlertTriangle className={styles.errorIcon} />
                </motion.p>
                <motion.p>{formStatus?.statusMsg}</motion.p>
              </motion.div>
            )}
          </AnimatePresence>

          <div className={styles.formBtn}>
            <Button
              tabIndex="2"
              type="submit"
              classes={[styles.submitButton]}
              disabled={isDisabled || formStatus.status === "PROCESSING"}
              isProcessing={isDisabled || formStatus.status === "PROCESSING"}
            >
              {t(buttonLabel)}
            </Button>
          </div>
        </form>
        <div className={styles.signup}>{footer}</div>
      </div>
    </div>
  );
}
