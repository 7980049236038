import { gql } from "@apollo/client";

/* ---- AUTHENTICATION ---- */
export const VALIDATE = gql`
  query Validate {
    validate {
      error
      user {
        id
        group
      }
    }
  }
`;

/* ---- ADVERTISER ---- */
export const GET_ADVERTISER = gql`
  query Advertiser(
    $userId: UUID
    $userName: String
    $email: String
    $limit: Int
  ) {
    advertiser(id: $userId, user_name: $userName, email: $email) {
      error
      advertiser {
        id
        user_name
        company_name
        email
        phone_cc
        phone_no
        company_website_url
        company_industry_area
        address_1
        address_2
        logo
        logo_uploaded
        city
        state
        country
        postal_code
        fax_number
        representative_name
        representative_dept
        representative_email
        active
        group
        created_at
        updated_at
        status
        show_joyride
        email_confirmed
        credit
        status
        status_description
        stripe_customer_id
        person_incharge_name
        person_incharge_designation
        person_incharge_phone_cc
        person_incharge_phone_no
        payment_method_exists
        payment_methods {
          id
          type
          card {
            brand
            country
            exp_month
            exp_year
            last4
          }
          billing_details {
            address {
              city
            }
            email
            name
            phone
          }
        }
        sessions(limit: $limit) {
          id
          created_at
          user_id
          event
        }
      }
    }
  }
`;

/* ---- CAMPAIGNS ---- */
export const GET_CAMPAIGN = gql`
  query Campaign($campaignId: UUID!) {
    campaign(id: $campaignId) {
      error
      campaign {
        id
        name
        advertiser_id
        start_date
        end_date
        status
        status_description
        slots {
          id
          date
          status
          view_count
          click_count
          banner_position_id
          banner_id
          banner_position {
            id
            position
          }
          banner {
            id
            name
            image
            link
          }
          price
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_MIN = gql`
  query Campaign($campaignId: UUID!) {
    campaign(id: $campaignId) {
      error
      campaign {
        id
        name
        advertiser_id
        start_date
        end_date
        status
        status_description
      }
    }
  }
`;

export const GET_CAMPAIGNS_MIN = gql`
  query Campaigns(
    $offset: Int
    $limit: Int
    $advertiserId: UUID
    $status: CampaignStatus
    $name: String
    $order: SortOrder
    $sort: String
  ) {
    campaigns(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiserId
      status: $status
      name: $name
      order: $order
      sort: $sort
    ) {
      error
      campaigns {
        id
        name
        advertiser_id
        start_date
        end_date
        status
      }
      total_count
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query Campaigns(
    $offset: Int
    $limit: Int
    $advertiserId: UUID
    $status: CampaignStatus
    $name: String
    $order: SortOrder
    $sort: String
  ) {
    campaigns(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiserId
      status: $status
      name: $name
      order: $order
      sort: $sort
    ) {
      error
      campaigns {
        id
        name
        advertiser_id
        start_date
        end_date
        status
        slots {
          id
          banner_position_id
          banner_id
          date
          price
          view_count
          click_count
        }
      }
      total_count
    }
  }
`;

export const GET_CAMPAIGNS_STATUS_COUNT = gql`
  query Campaigns(
    $offset: Int
    $limit: Int
    $advertiserId: UUID
    $status: CampaignStatus
    $name: String
    $order: SortOrder
    $sort: String
  ) {
    campaigns(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiserId
      status: $status
      name: $name
      order: $order
      sort: $sort
    ) {
      error
      # campaigns {
      #   id
      # }
      total_count
    }
  }
`;

/* ---- BANNERS ---- */
export const GET_BANNER = gql`
  query Banner($bannerId: UUID!) {
    banner(id: $bannerId) {
      error
      banner {
        id
        name
        image_uploaded
        image
        image_extension
        link
      }
    }
  }
`;

export const GET_BANNERS = gql`
  query Banners(
    $offset: Int
    $limit: Int
    $advertiserId: UUID
    $status: BannerStatus
    $imageUploaded: Boolean
    $order: SortOrder
    $sort: String
    $active: Boolean
  ) {
    banners(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiserId
      status: $status
      image_uploaded: $imageUploaded
      order: $order
      sort: $sort
      active: $active
    ) {
      banners {
        id
        name
        link
        image
        image_uploaded
        image_extension
        created_at
        status
        status_description
        active
      }
      total_count
      error
    }
  }
`;

export const GET_BANNERS_STATUS_COUNT = gql`
  query Banners(
    $offset: Int
    $limit: Int
    $advertiserId: UUID
    $status: BannerStatus
    $order: SortOrder
    $sort: String
    $active: Boolean
  ) {
    banners(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiserId
      status: $status
      order: $order
      sort: $sort
      active: $active
    ) {
      error
      # banners {
      #   id
      #   active
      # }
      total_count
    }
  }
`;

/* ---- BANNER POSITIONS ---- */
export const GET_BANNER_POSITION = gql`
  query BannerPosition($bannerPositionId: UUID!) {
    banner_position(id: $bannerPositionId) {
      error
      banner_position {
        id
        position
      }
    }
  }
`;

export const GET_BANNER_POSITIONS = gql`
  query BannerPositions(
    $offset: Int
    $limit: Int
    $position: String
    $maxStandardPricing: Float
    $minStandardPricing: Float
    $order: SortOrder
    $sort: String
  ) {
    banner_positions(
      offset: $offset
      limit: $limit
      position: $position
      max_standard_pricing: $maxStandardPricing
      min_standard_pricing: $minStandardPricing
      order: $order
      sort: $sort
    ) {
      banner_positions {
        id
        position
        text
        standard_pricing
      }
      total_count
      error
    }
  }
`;

export const GET_BANNER_POSITION_DATE_PRICINGS = gql`
  query BannerPositionDatePricings(
    $offset: Int
    $limit: Int
    $bannerPositionId: UUID
    $priceOn: Date
    $price: Float
    $order: SortOrder
    $sort: String
    $minPriceOn: Date
    $maxPriceOn: Date
  ) {
    banner_position_date_pricings(
      offset: $offset
      limit: $limit
      banner_position_id: $bannerPositionId
      price_on: $priceOn
      price: $price
      order: $order
      sort: $sort
      min_price_on: $minPriceOn
      max_price_on: $maxPriceOn
    ) {
      banner_position_date_pricings {
        id
        banner_position_id
        price_on
        price
      }
      total_count
      error
    }
  }
`;

export const GET_SLOTS_MAX = gql`
  query Slots($campaignId: UUID) {
    slots(campaign_id: $campaignId) {
      error
      slots {
        id
        banner_position_id
        banner_position {
          id
          position
        }
        banner {
          name
          link
          image
        }
        banner_id
        date
        price
        view_count
        click_count
      }
      total_count
    }
  }
`;

/* ---- SLOTS ---- */
export const GET_SLOTS = gql`
  query Slots(
    $offset: Int
    $limit: Int
    $campaignId: UUID
    $bannerId: UUID
    $bannerPositionId: UUID
    $price: Int
    $date: Date
    $maxDate: Date
    $minDate: Date
    $status: CampaignStatus
  ) {
    slots(
      offset: $offset
      limit: $limit
      campaign_id: $campaignId
      banner_id: $bannerId
      banner_position_id: $bannerPositionId
      price: $price
      date: $date
      max_date: $maxDate
      min_date: $minDate
      status: $status
    ) {
      slots {
        id
        date
        banner_position_id
        banner_position {
          id
          position
        }
        status
        view_count
        click_count
      }
      total_count
      error
    }
  }
`;

/* ---- CREDITS ---- */
export const GET_CREDITS = gql`
  query Credits(
    $offset: Int
    $limit: Int
    $transactionID: UUID
    $userID: UUID
    $bankTransactionID: String
    $amount: Int
    $order: SortOrder
    $sort: String
  ) {
    credits(
      offset: $offset
      limit: $limit
      transaction_id: $transactionID
      user_id: $userID
      bank_transaction_id: $bankTransactionID
      amount: $amount
      order: $order
      sort: $sort
    ) {
      credits {
        id
        user_id
        transaction_id
        amount
        bank_transaction_id
        created_at
        updated_at
      }
      total_count
      error
    }
  }
`;

/* ---- TRANSACTIONS ---- */
export const GET_TRANSACTIONS = gql`
  query Transactions(
    $offset: Int
    $limit: Int
    $userID: UUID
    $amount: Int
    $order: SortOrder
    $sort: String
    $createdAt: Datetime
  ) {
    transactions(
      offset: $offset
      limit: $limit
      user_id: $userID
      amount: $amount
      order: $order
      sort: $sort
      created_at: $createdAt
    ) {
      transactions {
        id
        user_id
        amount
        ref_id
        status
        transaction_type
        created_at
        updated_at
      }
      total_count
      error
    }
  }
`;

export const GET_MASTERLIST_COUNTRIES = gql`
  query MasterlistCountries(
    $offset: Int
    $limit: Int
    $country: String
    $locale: String
    $order: SortOrder
    $sort: String
  ) {
    masterlist_countries(
      offset: $offset
      limit: $limit
      order: $order
      sort: $sort
      country: $country
      locale: $locale
    ) {
      error
      masterlist_countries {
        id
        country
        locale
        states {
          id
          country_id
          state
          locale
        }
      }
      total_count
    }
  }
`;

export const GET_MASTERLIST_STATES = gql`
  query MasterlistStates(
    $offset: Int
    $limit: Int
    $countryId: UUID
    $state: String
    $locale: String
    $order: SortOrder
    $sort: String
  ) {
    masterlist_states(
      offset: $offset
      limit: $limit
      country_id: $countryId
      order: $order
      sort: $sort
      state: $state
      locale: $locale
    ) {
      error
      masterlist_states {
        id
        country_id
        state
      }
      total_count
    }
  }
`;

export const GET_TICKETS = gql`
  query Tickets(
    $offset: Int
    $limit: Int
    $advertiserId: UUID
    $assignedTo: UUID
    $status: AdvertiserTicketStatus
    $order: SortOrder
    $sort: String
  ) {
    advertiser_tickets(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiserId
      assigned_to: $assignedTo
      status: $status
      order: $order
      sort: $sort
    ) {
      advertiser_tickets {
        id
        description
        status_description
        created_at
        updated_at
        status
      }
      total_count
      error
    }
  }
`;

export const TICKET_CHAT_MESSAGES = gql`
  query ticketChatMessages(
    $ticket_id: UUID!
    $start: String
    $stop: String
    $limit: Int
    $reverse: Boolean
  ) {
    ticket_chat_messages(
      ticket_id: $ticket_id
      start: $start
      stop: $stop
      limit: $limit
      reverse: $reverse
    ) {
      error
      ticket_chat_messages {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
    }
  }
`;

export const ADVERTISER_FAQ = gql`
  query faqs($limit: Int, $type: FAQType) {
    faqs(limit: $limit, type: $type) {
      error
      faqs {
        id
        question
        answer
        created_at
        updated_at
        locale
      }
    }
  }
`;
