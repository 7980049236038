import { useState } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { IconButton, MenuItem } from "@material-ui/core";
import { useUserState } from "contexts/userContext";

import {
  MdPersonOutline,
  MdSettings,
  MdPowerSettingsNew,
  MdVpnKey,
  MdCardMembership,
} from "react-icons/md";
import { MenuWrapper, NotifcationToast } from "components";
import { LOGOUT } from "services/mutations";
import { useUserContext } from "contexts/userContext";

function UserMenu({ handleClose, handleLogout, user }) {
  const { t } = useTranslation();
  const { id } = useUserState();

  const testVar = useParams();

  // Only show some menu items when user is approved
  return (
    <>
      {user.status === "approved" && (
        <Link to={`/${id}/profile`} style={{ textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>
            <MdSettings style={{ marginRight: ".5rem" }} />
            {t("my account")}
          </MenuItem>
        </Link>
      )}
      {user.status === "approved" && (
        <Link to="/password-change" style={{ textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>
            <MdVpnKey style={{ marginRight: ".5rem" }} />
            {t("password reset")}
          </MenuItem>
        </Link>
      )}
      {user.status === "approved" && (
        <Link to="/saved-cards" style={{ textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>
            <MdCardMembership style={{ marginRight: ".5rem" }} />
            {t("saved cards")}
          </MenuItem>
        </Link>
      )}

      <MenuItem onClick={handleLogout}>
        <MdPowerSettingsNew style={{ marginRight: ".5rem" }} />
        {t("logout")}
      </MenuItem>
    </>
  );
}

export default function UserProfileMenu({ user }) {
  const { t } = useTranslation(["common"]);
  const [state] = useUserContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* ---- Handle Logout ---- */
  const history = useHistory();
  const [logout] = useMutation(LOGOUT);
  const [logoutErr, setLogoutErr] = useState(null);

  const handleLogout = async () => {
    const res = await logout();

    if (res?.data?.logout?.error) {
      setLogoutErr(true);
    } else if (res?.data?.logout?.logged_out) {
      localStorage.removeItem("meem-adv-auth");
      history.push("/login");
    }
  };

  return (
    <>
      {logoutErr && (
        <NotifcationToast
          isOpen={logoutErr}
          message={t("logout failed. please try again.")}
          mode="error"
        />
      )}
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-label="User Profile"
        color="inherit"
        onClick={handleMenuClick}
      >
        <MdPersonOutline />
      </IconButton>
      <MenuWrapper
        user={user}
        anchorEl={anchorEl}
        handleClose={handleClose}
        menuListComp={
          <UserMenu
            handleClose={handleClose}
            handleLogout={handleLogout}
            user={state}
          />
        }
      />
    </>
  );
}
